<template>
  <div class="change-password">
    <div class="container">
      <ChangePasswordForm />
    </div>
  </div>
</template>

<script>
import ChangePasswordForm from '@/components/Auth/ChangePasswordForm.vue';

export default {
  components: {
    ChangePasswordForm,
  },
};
</script>

<style lang="sass" scoped>
.change-password
  position: relative
  z-index: 2
</style>
