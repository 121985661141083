<template>
  <form class="change-password-form" novalidate @submit.prevent>
    <h1 class="change-password-form__title">Change password</h1>
    <div class="change-password-form__subtitle text">
      Input your new desired password in the input fields below to create a new
      password. We strongly advise you to store it safely.
    </div>
    <div class="change-password-form__items">
      <div class="change-password-form__item">
        <LabelField
          id="password"
          class="change-password-form__label"
          title="New password"
        />
        <TextField
          id="password"
          v-model.trim="password"
          name="password"
          required
          class="change-password-form__input"
          type="password"
          is-password
          :password-strength="passwordStrength"
          :error="isPasswordError"
          :error-txt="passwordErrorText"
          tooltip-text="Password must be at least 8 characters with 1 upper case letter and 1 number."
          @focus="resetServerError('password')"
        />
      </div>
      <div class="change-password-form__item">
        <LabelField
          id="confirm-password"
          class="change-password-form__label"
          title="Password confirmation"
        />
        <TextField
          id="confirm-password"
          v-model.trim="confirmPassword"
          name="confirm-password"
          required
          class="change-password-form__input"
          type="password"
          is-password
          :password-strength="confirmPasswordStrength"
          :error="isConfirmPasswordError"
          :error-txt="confirmPasswordErrorText"
          tooltip-text="Password must be at least 8 characters with 1 upper case letter and 1 number."
          @focus="resetServerError('password_confirmation')"
        />
      </div>
    </div>
    <div class="change-password-form__controls">
      <ButtonBase :loading="confirmLoader" @click="changePasswordHandler"
        >Confirm</ButtonBase
      >
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex';
import changePasswordValidation from '@/mixins/validation/forms/changePassword.js';
import LabelField from '@/elements/LabelField';

export default {
  components: {
    LabelField,
  },
  mixins: [changePasswordValidation],
  data() {
    return {
      confirmLoader: false,
      password: '',
      confirmPassword: '',
      loadingSubmitBtn: false,
    };
  },
  computed: {
    ...mapGetters(['getResetPasswordData']),
  },
  methods: {
    async changePasswordHandler() {
      if (this.isInvalidForm) {
        this.showValidationErrors();
        return;
      }
      this.confirmLoader = true;
      await this.$store.dispatch('resetPasswordConfirm', {
        email: this.getResetPasswordData.email,
        code: this.getResetPasswordData.code,
        password: this.password,
        password_confirmation: this.confirmPassword,
      });
      this.confirmLoader = false;
    },
  },
};
</script>

<style lang="sass" scoped>
.change-password-form__subtitle
  margin-top: 12px
  margin-bottom: 64px
  @include ms
    margin-top: 4px
    margin-bottom: 40px

.change-password-form__items
  display: grid
  grid-template-columns: 1fr 1fr
  gap: 24px 40px
  @include ms
    grid-template-columns: 1fr

.change-password-form__controls
  display: flex
  justify-content: flex-end
  margin-top: 64px
  @include ms
    margin-top: 40px
</style>
